var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"6"}},[_c('v-card',{ref:"form"},[_c('v-card-title',[_vm._v(" Create TX Approval Environment ")]),_c('v-card-text',[_c('validation-observer',{ref:"valObserver"},[_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Company Name:")]),_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Registered Business or Non-Profit Organization Name","persistent-hint":""},model:{value:(_vm.company.companyName),callback:function ($$v) {_vm.$set(_vm.company, "companyName", $$v)},expression:"company.companyName"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Environment Name:")]),_c('validation-provider',{attrs:{"name":"Tenant Name","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Environment name that will be used for logging in","persistent-hint":""},model:{value:(_vm.company.environment),callback:function ($$v) {_vm.$set(_vm.company, "environment", $$v)},expression:"company.environment"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Name:")]),_c('validation-provider',{attrs:{"name":"Full Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Your full name","persistent-hint":""},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"Email address","persistent-hint":""},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("PIN:")]),_c('validation-provider',{attrs:{"name":"PIN","rules":"required|numeric|min:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"4-digit number","persistent-hint":"","type":"password"},model:{value:(_vm.company.pin),callback:function ($$v) {_vm.$set(_vm.company, "pin", $$v)},expression:"company.pin"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Device Name:")]),_c('validation-provider',{attrs:{"name":"Device Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"The name of your mobile device to help you identify it.","persistent-hint":""},model:{value:(_vm.company.deviceName),callback:function ($$v) {_vm.$set(_vm.company, "deviceName", $$v)},expression:"company.deviceName"}})]}}])}),_c('span',{staticClass:"font-weight-bold black--text"},[_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])]),_vm._v("Device ID:")]),_c('validation-provider',{attrs:{"name":"Device ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","required":"","error-messages":errors,"hint":"The unique ID of your device. You can get it by launching the TX Approval app on your device.","persistent-hint":""},model:{value:(_vm.company.deviceId),callback:function ($$v) {_vm.$set(_vm.company, "deviceId", $$v)},expression:"company.deviceId"}})]}}])}),_c('vue-recaptcha',{attrs:{"sitekey":"6Lf3EnAdAAAAAKMQFnGd55hWxGJRKZg23Q-8r1_o","load-recaptcha-script":true},on:{"verify":_vm.validateCap}})],1)],1),_c('v-divider',{staticClass:"mt-12"}),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("IMPORTANT:")]),_vm._v("  By submitting this form, you are agreeing with "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.termsClicked}},[_vm._v(" Terms Of Use ")]),_vm._v(" and "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.privacyClicked}},[_vm._v(" Privacy Policy ")]),_vm._v(" of TX Approval application. "),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }